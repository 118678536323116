@import './Variables.scss';

.game-dialog {
    .MuiPaper-root {
        background-color: white;
        padding: 2.1rem 0;
        margin: 1rem;
        color: black;
    }

    .MuiDialog-paperFullWidth {
        width: 100%;
    }

    .MuiDialogTitle-root {
        padding: 0;

        h2 {
            text-align: center;
            color: black;
            font-size: 2rem;
            font-weight: bold;
        }
    }
    
    .sub-title {
        text-align: center;
        margin-bottom: 2rem;
    }

    .close-btn {
        color: $base-color;
        position: absolute;
        top: 0.4rem;
        right: 0.4rem;

        .MuiIcon-root {
            font-size: 2rem;
        }
    }
}