.track-container {
    margin-top: 4rem;

    .track-square-container {
        height: 5rem;
        background-color: #B3A369; //THWG
        background: radial-gradient(circle, rgb(204,222,95) -50%, #B3A369 100%);

        &.MuiCard-root {
            overflow: visible;
        }

        .track-square-title {
            margin-left: 0.3rem;
            color: white;
        }

        .desert {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            color: black;
            width: 3rem;
            height: 3rem;
            border-radius: 2rem;
            margin: auto;
            margin-top: -.5rem;
            
            .MuiSvgIcon-root {
                font-size: 2.5rem;
            }
        }
    }
}

.track-title {
    margin-bottom: 1.25rem;
}

.finish-square {
    position: relative;

    .finish-flag {
        background-image: linear-gradient(45deg, #333 25%, transparent 25%),
                          linear-gradient(-45deg, #333 25%, transparent 25%),
                          linear-gradient(45deg, transparent 75%, #333 75%),
                          linear-gradient(-45deg, transparent 75%, #333 75%);
        background-size: 1.25rem 1.25rem;
        background-position: 0 0, 0 0.625rem, 0.625rem -0.625rem, -0.625rem 0;
        height: 100%;
        width: 1.8rem;
        position: absolute;
        top: 0;
        right: 0;
    }
}