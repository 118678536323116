@import './Variables.scss';

.fullscreen-btn {
    &.MuiButtonBase-root {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
        color: $base-color;
        z-index: 2;
    }

    &.white-btn {
        color: white
    }
}