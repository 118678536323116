.camel-container {
    position: relative;
    width: 5rem;
    
    .camel-name {
        position: absolute;
        z-index: 10;
        font-size: 0.6rem;
        width: 4rem;
        text-align: center;
        opacity: 0;
        transition: all 500ms;
        cursor: default;
        user-select: none;
        color: white;
    }

    .camel-hat {
        position: absolute;
        width: 2rem;
        right: -0.1rem;
        top: -0.3rem;

        &.donald {
            svg {
                animation: donald 15s infinite ease-in-out;
                transform-origin: left;

                @keyframes donald {
                    0% {
                        transform: rotateZ(0deg);
                    }
                    45% {
                        transform: rotateZ(0deg);
                    }
                    48% {
                        transform: rotateZ(-70deg);
                    }
                    50% {
                        transform: rotateZ(-40deg);
                    }
                    53% {
                        transform: rotateZ(-80deg);
                    }
                    55% {
                        transform: rotateZ(0deg);
                    }
                    100% {
                        transform: rotateZ(0deg);
                    }
                }
            }
        }
    }

    &:hover {
        .camel-name {
            opacity: 1;
        }
    }
}
