.score-container {
    padding: 0 0.5rem;
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 20rem;
}

.scoreboard-title {
    margin-bottom: 1.25rem;
}