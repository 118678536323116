body {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  body {
    background-color: #ffffff;
  }

  html {
    font-size: 10px;
  }
}

@media only screen and (max-height: 500px) {
  html {
    font-size: 10px;
  }
}

.App {
  text-align: center;
}

a {
  color: #0080FF
}

.section {
  margin: 0.625rem 0;
}

button {
  margin: 0.625rem 0.3125rem;
  padding: 0.625rem;
  font-size: 1rem;
}

button.small {
  padding: 0;
  font-size: 0.75rem;
}

button.buyItem {
  margin: 0 0.3125rem;
}

.item {
  margin: 0.3125rem 0;
}

.dead {
  text-decoration: line-through;
  margin: 0 0.25rem;
}

.playerColor0 {
  color: #ff5050;
}

.playerColor1 {
  color: #13b713;
}

.playerColor2 {
  color: #8787ff;
}

.playerColor3 {
  color: #BADA55;
}

.playerColor4 {
  color: #FF00FF
}

.playerColor5 {
  color: #00FFFF
}

.playerColor6 {
  color: #CC0000
}

.playerColor7 {
  color: #00CC00
}

.playerColor8 {
  color: #0000CC
}

.playerColor9 {
  color: #CCCC00
}

.playerColor10 {
  color: #CC00CC
}

.playerColor11 {
  color: #00CCCC
}

.MuiSnackbar-root {
  .MuiPaper-root {
    background-color: white;
    color: black;
  }
}
