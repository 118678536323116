$color: #6e4f1c;
$speed: 5;
$animationCount: 1;
$diceSize: 3;

.pyramid-container {
    width: 100%;
    height: 0;
    margin: 0 auto;
    position: relative;
    perspective: 18.75rem;
    perspective-origin: 50% 40%;

    .pyramid {
        transition: 1s all;
        perspective: 18.75rem;
        perspective-origin: 50% 40%;
        cursor: pointer;
    }

    .scene {
        width: 0;
        height: 0;
        position: relative;
        left: 49%;
        top: 4.25rem;
        perspective: 37.5rem;
        opacity: 0;
        z-index: -1;

        .cube {
            width: 100%;
            height: 100%;
            position: relative;
            transform-style: preserve-3d;
            transform: translateZ(-2rem) rotateY(15deg) rotateX(-5deg);
        }
        
        .cube__face {
            position: absolute;
            width: $diceSize+rem;
            height: $diceSize+rem;
            color: white;
            font-size: ($diceSize - 1)+rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .cube__face--front  { transform: rotateY(  0deg) translateZ($diceSize/2+rem); }
        .cube__face--right  { transform: rotateY( 90deg) translateZ($diceSize/2+rem); }
        .cube__face--back   { transform: rotateY(180deg) translateZ($diceSize/2+rem); }
        .cube__face--left   { transform: rotateY(-90deg) translateZ($diceSize/2+rem); }
        .cube__face--top    { transform: rotateX( 90deg) translateZ($diceSize/2+rem); }
        .cube__face--bottom { transform: rotateX(-90deg) translateZ($diceSize/2+rem); }
    }
    
    
    &.animate{
        .pyramid {
            animation: rollDicePyramid $speed+s $animationCount ease-in-out;
        }

        .front {
            animation: rollDiceFront $speed+s $animationCount ease-in-out;
        }

        .left {
            animation: rollDiceLeft $speed+s $animationCount ease-in-out;
        }

        .shadow {
            animation: shadow $speed+s $animationCount ease-in-out;
        }

        .scene {
            animation: dice $speed+s $animationCount ease-in-out;
        }
    }
}

.side {
    position: absolute;
    left: 38%;
    top: -1.25rem;
    width: 7.5rem;
    height: 0;
    border-left: 1.875rem solid transparent;
    border-right: 1.875rem solid transparent;
    border-bottom: 7.5rem solid #ccc;
    transform-origin: 50% 0;
}

.front {
    transform: rotateY(14deg) rotateX(17deg) translateX(1.8125rem);
    border-bottom-color: lighten($color, 5%);
}

.right {
    opacity: 0;
    transform: rotateY(135deg) rotateX(30deg);
    border-bottom-color: darken($color, 5%);
}

.back {
    transform: rotateY(225deg) rotateX(30deg);
    border-bottom-color: lighten($color, 5%);
    opacity: 0;
}

.left {
    transform: rotateY(296deg) rotateX(18deg) translateX(-1.8125rem);
    border-bottom-color: darken($color, 5%);
}

@keyframes rollDicePyramid {
    0% {
        transform: rotateZ(0deg);
    }
    30% {
        transform: rotateZ(-180deg);
    }
    45% {
        transform: rotateZ(-180deg) translateY(-6.25rem);
    }
    55% {
        transform: rotateZ(-180deg) translateY(-3.125rem);
    }
    70% {
        transform: rotateZ(-180deg);
    }
    100% {
        transform: rotateZ(0deg);
    }
}

@keyframes rollDiceFront {
    0% {
        transform: rotateY(14deg) rotateX(17deg) translateX(1.8125rem);
        border-bottom-color: lighten($color, 5%);
    }
    30% {
        transform: rotateY(75deg) rotateX(15deg) translateX(1.8125rem);
        border-bottom-color: darken($color, 5%);
    }
    45% {
        border-bottom-color: darken($color, 12%);
    }
    55% {
        border-bottom-color: darken($color, 12%);
    }
    70% {
        transform: rotateY(75deg) rotateX(15deg) translateX(1.8125rem);
        border-bottom-color: darken($color, 5%);
    }
    100% {
        transform: rotateY(14deg) rotateX(17deg) translateX(1.8125rem);
        border-bottom-color: lighten($color, 5%);
    }
}

@keyframes rollDiceLeft {
    0% {
        transform: rotateY(296deg) rotateX(18deg) translateX(-1.8125rem);
        border-bottom-color: darken($color, 5%);
    }
    30% {
        transform: rotateY(345deg) rotateX(15deg) translateX(-1.8125rem);
        border-bottom-color: lighten($color, 5%);
    }
    45% {
        border-bottom-color: darken($color,2%);
    }
    55% {
        border-bottom-color: darken($color, 2%);
    }
    70% {
        transform: rotateY(345deg) rotateX(15deg) translateX(-1.8125rem);
        border-bottom-color: lighten($color, 5%);
    }
    100% {
        transform: rotateY(296deg) rotateX(18deg) translateX(-1.8125rem);
        border-bottom-color: darken($color, 5%);
    }
}

.shadow {
    position: absolute;
    left: 43%;
    top: 4.125rem;
    z-index: -1;
    transform: rotateX(90deg);
    width: 3.125rem;
    height: 3.125rem;
    background-color: #bbb;
    box-shadow: 0 0 2.5rem 2.5rem #bbb;
    transform: rotateX(90deg) rotateZ(45deg);
}

@keyframes shadow {
    0% {
        transform: rotateX(90deg) rotateZ(45deg);
        box-shadow: 0 0 2.5rem 2.5rem #bbb;
        background-color: #bbb;
    }
    15% {
        transform: rotateX(90deg) rotateZ(45deg) translateX(3.125rem) translateY(1.25rem);
        box-shadow: 0 0 2.5rem 3.125rem #ccc;
        background-color: #ccc;
    }
    30% {
        transform: rotateX(90deg) rotateZ(60deg) translateX(3.125rem);
        box-shadow: 0 0 2.5rem 3.75rem #ddd;
        background-color: #ddd;
    }
    45% {
        transform: rotateX(90deg) rotateZ(75deg) translateX(3.125rem);
        box-shadow: 0 0 2.5rem 1.5625rem #bbb;
        background-color: #bbb;
    }
    55% {
        transform: rotateX(90deg) rotateZ(75deg) translateX(3.125rem);
        box-shadow: 0 0 2.5rem 1.875rem #bbb;
        background-color: #bbb;
    }
    70% {
        transform: rotateX(90deg) rotateZ(75deg) translateX(3.125rem);
        box-shadow: 0 0 2.5rem 3.75rem #ddd;
        background-color: #ddd;
    }
    85% {
        transform: rotateX(90deg) rotateZ(60deg) translateX(3.125rem) translateY(1.25rem);
        box-shadow: 0 0 2.5rem 3.125rem #ccc;
        background-color: #ccc;
    }
    100% {
        transform: rotateX(90deg) rotateZ(45deg);
        box-shadow: 0 0 2.5rem 2.5rem #bbb;
        background-color: #bbb;
    }
}

@keyframes dice {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    45.1% {
        opacity: 1;
        z-index: -1;
    }
    55% {
        z-index: -1;
    }
    55.1% {
        z-index: 10;
    }
    95% {
        opacity: 1;
    }
    100% {
        z-index: 10;
        opacity: 0;
    }
}
