//$base-color: #1d14a0;
$base-color: brown;

@mixin base-btn {
    &.MuiButton-root {
        background-color: white;
        color: $base-color;
        font-size: 1.3rem;
        font-weight: bold;
        letter-spacing: -0.0625rem;
        width: 14rem;

        &:disabled {
            background-color: #ccc;
            opacity: 0.6;
            color: $base-color;

            .MuiCircularProgress-colorPrimary {
                color: white;
            }
        }

        .MuiCircularProgress-root {
            width: 2.5rem !important;
            height: 2.5rem !important;
        }
    }
}

@mixin base-btn-alt {
    &.MuiButton-root {
        color: white;
        background-color: $base-color;
        font-size: 1.3rem;
        font-weight: bold;
        letter-spacing: -0.0625rem;
        width: 14rem;
        
        &:disabled {
            background-color: $base-color;
            opacity: 0.3;
        }
    }
    
    &.MuiButton-contained:hover {
        &.Mui-disabled {
            background-color: $base-color;
        }
    }
    
            .MuiCircularProgress-root {
                width: 2.5rem !important;
                height: 2.5rem !important;
            }
}