@import './Variables.scss';

.camel-edit-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .camel-container {
        margin: 2rem;
        color: white;
    }

    .camel-color {
        margin-right: 3rem;

        input {
            width: 5rem;
            height: 2.5rem;
        }
    }

    .update-camel-btn {
        @include base-btn-alt;
        margin-top: 3rem;
    }
}