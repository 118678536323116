@import '../Variables.scss';

.bet-info-dialog {
    .MuiPaper-root {
        background-color: white;
        padding: 2.1rem 0;
        margin: 1rem;
        color: black;
    }

    .MuiDialog-paperFullWidth {
        width: 100%;
    }

    .MuiDialogTitle-root {
        padding: 0;

        h2 {
            text-align: center;
            color: black;
            font-size: 2rem;
        }
    }

    .camel-card {
        &.MuiPaper-root {
            width: 10rem;
            padding: 1rem;
            margin: 1rem 0.5rem;

            span {
                display: flex;
                flex-direction: column;
                font-size: 1rem;
                width: 10rem;
            }
        }
    }

    .camel {
        width: 2rem;
        margin-bottom: 0.4rem;
    }

    .close-btn {
        color: $base-color;
        position: absolute;
        top: 0.4rem;
        right: 0.4rem;

        .MuiIcon-root {
            font-size: 2rem;
        }
    }

    .bet-player-container {
        margin: 1rem 2rem;
    }
}