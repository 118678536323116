@import './Variables.scss';

.bet-container {
    .place-bet-btn {
        @include base-btn-alt;
        margin-top: 2rem;
    }

    .select-camel-btn {
        @include base-btn-alt;
        width: 1rem;
        margin-top: 1rem;
    }

    .camel-btn {
        @include base-btn;
    }

    .select-camel-btn,
    .camel-btn {
        &.MuiButton-root {
            width: 10rem;
            padding: 1rem;
            margin: 1rem 0.5rem;

            span {
                display: flex;
                flex-direction: column;
                font-size: 1rem;
                width: 10rem;
            }
        }

        .camel-container {
            width: 2.3rem;
            padding-top: 0.3rem;
        }
    }

    .camel {
        width: 2rem;
        margin-bottom: 0.4rem;
    }
}