.MuiTimelineItem-missingOppositeContent:before {
    display: none;
}

.MuiTimeline-root {
    max-height: 20rem;
    overflow-y: scroll;
    margin: 0;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 1rem;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: .5rem;
        background-color: rgba(199, 199, 199, .5);
        box-shadow: 0 0 .1rem rgba(255, 255, 255, .5);
    }

    .MuiTimelineItem-root {
        min-height: 2.5rem;

        .MuiTimelineDot-root {
            margin: 0;
        }

        .MuiTimelineSeparator-root {
            margin-top: .1rem;
        }

        .MuiTimelineContent-root {
            font-size: 0.9rem;
        }
    }
}