@import './Variables.scss';

.score-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem;
    width: 7rem;
    
    &.MuiPaper-root {
        background-color: white;
        color: black;
    }

    .camel-container {
        width: auto;
    }
    
    .camel {
        width: 2rem;
        margin-bottom: 0.4rem;
    }

    .camel-name {
        font-size: 1rem;
        height: 1.3rem;
        white-space: nowrap;
    }

    .move-count {
        font-size: 1.3rem;
        font-weight: bold;
        height: 1.3rem;
    }
}

.moves-title {
    margin: 0;
    font-size: 2rem;
    text-align: center;
    width: 100%;
}
