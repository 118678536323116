@import '../Variables.scss';

.game-settings-dialog {
    .MuiPaper-root {
        background-color: white;
        padding: 0.6rem 2rem 2rem 2rem;
        margin: 1rem;
        color: black;
    }

    .MuiDialog-paperFullWidth {
        width: 100%;
    }

    .MuiDialogTitle-root {
        padding: 0;

        h2 {
            text-align: center;
            color: black;
            font-size: 2rem;
        }
    }

    .close-btn {
        color: $base-color;
        position: absolute;
        top: 0.4rem;
        right: 0.4rem;

        .MuiIcon-root {
            font-size: 2rem;
        }
    }

    .MuiCheckbox-root {
        color: $base-color;

        &.MuiCheckbox-colorSecondary.Mui-checked {
            color: $base-color;
        }
    }

    .button-settings-container {
        margin-left: 2rem;
    }
}