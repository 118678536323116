@import './Variables.scss';

.desert-container {
    .select-direction-btn {
        @include base-btn-alt;
        margin-top: 2rem;
    }

    .direction-btn {
        @include base-btn;
        margin-top: 2rem;
    }

    .select-square-btn {
        @include base-btn-alt;

        &.MuiButton-root {
            width: 1rem;
            margin: 1em 0.5rem;
        }
    }
    
    .square-btn {
        @include base-btn;
     
        &.MuiButton-root {
            width: 1rem;
            margin: 1em 0.5rem;
        }
    }
}