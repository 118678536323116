@import '../Variables.scss';

.fab-btn-container {
    position: absolute;
    transition: all 500ms;

    .MuiFab-root {
        position: absolute;
        right: 0;
        color: white;
        background-color: $base-color;
        z-index: 99;
        transition: all 500ms;

        &:hover {
            background-color: grey;
        }
        
        .MuiIcon-root {
            font-size: inherit;
        }
    }
}